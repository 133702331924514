import React from 'react'
import classnames from 'classnames'

export default ({visible}) => {
    return (
        <div className={classnames("spinner", visible ? "active" : "")}>
            <div className="cssload-spin-box"></div>
        </div>
    )
}

