import React from "react"
const defaultSettings = {currency: '-', decimalPlaces: 0}

export default (price, settings_) => {
    const settings = Object.assign({}, defaultSettings, settings_)
    let formatted = '--'
    //const round = Math.pow(10, settings.decimalPlaces)

    if(typeof price !== 'undefined' && !isNaN(price)){
        //formatted = (Math.round(price * round) / round).toLocaleString()
        formatted = (price.toFixed(settings.decimalPlaces)).toLocaleString()
    }

    return formatted + '' + settings.currency
}
