export default(e, keyCode) => {
    if (e.keyCode !== keyCode ||
        e.repeat ||
        e.ctrlKey ||
        e.altKey ||
        e.shiftKey ||
        (e.target.tagName === 'INPUT' ||
            e.target.tagName === 'SELECT' ||
            e.target.tagName === 'TEXTAREA' ||
            e.target.isContentEditable
        )) {
        return false
    }
    return true
}