import React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Subheader from "../components/Subheader"
import Message from "../components/Message"
import {Container} from "react-bootstrap"
import IntlMessage from "../components/IntlMessage"

export default () => {

    return(
        <Container className='index-page'>
            <Header loading={false}/>
            <Subheader/>
            <main className="page-404">
                <div>
                    <Message h1={<IntlMessage id="heading_404"/>} h2={<IntlMessage id="message_not_found" />}/>
                </div>
            </main>
            <Footer/>
        </Container>
    )
}