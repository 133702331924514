import React from "react"
import {Row, Col} from 'react-bootstrap'
import IntlMessage from "./IntlMessage";

export default (props) => {
    const {heading = '', background = '', loading} = props
    let className = ''
    let bg = {}
    if(!loading){
        if(background){
            bg.backgroundImage = `url(${background})`
        }else{
            className = 'default'
        }
    }
    return (
        <header style={bg} className={className}>
            <Row>
                <Col lg={7} xs={9}>
                    <h1><IntlMessage id="heading_order"/></h1>
                    <h2>{heading || ''}</h2>
                </Col>
            </Row>
        </header>
    )
}