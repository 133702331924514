import React from "react";

export default ({h1, h2}) => {
    return(
        <>
            <h1>{h1}</h1>
            {
                h2 && <h2>{h2}</h2>
            }
        </>
    )
}