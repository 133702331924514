import React from "react"

export default () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" id="icon-CGM-svet-2" viewBox="0 0 32 32.001">
            <circle id="Ellipse_90" data-name="Ellipse 90" cx="16" cy="16" r="16" transform="translate(0 0)" fill="#fff"/>
            <g id="Intersection_5" data-name="Intersection 5" transform="translate(0 -13)" fill="#fff">
                <path d="M 16.00019645690918 19.49979972839355 C 12.92098426818848 19.49979972839355 10.07548236846924 18.03288841247559 8.289157867431641 15.55074310302734 C 10.62808895111084 14.20722961425781 13.28267097473145 13.49960041046143 16.00019645690918 13.49960041046143 C 18.71746063232422 13.49960041046143 21.37194061279297 14.20725154876709 23.71093559265137 15.55081844329834 C 21.92446899414062 18.03292846679688 19.07908821105957 19.49979972839355 16.00019645690918 19.49979972839355 Z" stroke="none"/>
                <path d="M 16.00019836425781 13.99959945678711 C 13.56297492980957 13.99959945678711 11.17812538146973 14.58769226074219 9.040470123291016 15.7085132598877 C 10.74173927307129 17.78645324707031 13.27423477172852 18.99979782104492 16.00019836425781 18.99979782104492 C 18.72586822509766 18.99979782104492 21.25823020935059 17.78649711608887 22.9596004486084 15.70856666564941 C 20.82190895080566 14.58770751953125 18.43717575073242 13.99959945678711 16.00019836425781 13.99959945678711 M 16.00019836425781 12.99959945678711 C 19.09049606323242 12.99959945678711 21.97607803344727 13.87580871582031 24.42239761352539 15.39337921142578 C 22.6443977355957 18.16423797607422 19.53663635253906 19.99979782104492 16.00019836425781 19.99979782104492 C 12.46318817138672 19.99979782104492 9.35551643371582 18.16415786743164 7.577716827392578 15.3932991027832 C 10.02402687072754 13.87572860717773 12.90958786010742 12.99959945678711 16.00019836425781 12.99959945678711 Z" stroke="none" fill="#1988d9"/>
            </g>
            <g id="Intersection_6" data-name="Intersection 6" transform="translate(32 45) rotate(180)" fill="#fff">
                <path d="M 16.00019645690918 19.49979972839355 C 12.92098426818848 19.49979972839355 10.07548236846924 18.03288841247559 8.289157867431641 15.55074310302734 C 10.62808895111084 14.20722961425781 13.28267097473145 13.49960041046143 16.00019645690918 13.49960041046143 C 18.71746063232422 13.49960041046143 21.37194061279297 14.20725154876709 23.71093559265137 15.55081844329834 C 21.92446899414062 18.03292846679688 19.07908821105957 19.49979972839355 16.00019645690918 19.49979972839355 Z" stroke="none"/>
                <path d="M 16.00019836425781 13.99959945678711 C 13.56297492980957 13.99959945678711 11.17812538146973 14.58769226074219 9.040470123291016 15.7085132598877 C 10.74173927307129 17.78645324707031 13.27423477172852 18.99979782104492 16.00019836425781 18.99979782104492 C 18.72586822509766 18.99979782104492 21.25823020935059 17.78649711608887 22.9596004486084 15.70856666564941 C 20.82190895080566 14.58770751953125 18.43717575073242 13.99959945678711 16.00019836425781 13.99959945678711 M 16.00019836425781 12.99959945678711 C 19.09049606323242 12.99959945678711 21.97607803344727 13.87580871582031 24.42239761352539 15.39337921142578 C 22.6443977355957 18.16423797607422 19.53663635253906 19.99979782104492 16.00019836425781 19.99979782104492 C 12.46318817138672 19.99979782104492 9.35551643371582 18.16415786743164 7.577716827392578 15.3932991027832 C 10.02402687072754 13.87572860717773 12.90958786010742 12.99959945678711 16.00019836425781 12.99959945678711 Z" stroke="none" fill="#1988d9"/>
            </g>
            <g id="Intersection_1" data-name="Intersection 1" transform="translate(-15 -21)" fill="none">
                <path d="M16.534,30.155a16.006,16.006,0,0,1,28.932,0A55.392,55.392,0,0,1,31,32,55.387,55.387,0,0,1,16.534,30.155Z" stroke="none"/>
                <path d="M 30.99959945678711 31.00040054321289 C 35.49715423583984 31.00040054321289 39.96134185791016 30.48404884338379 43.99476623535156 29.50192832946777 C 42.80422592163086 27.44129753112793 41.13832092285156 25.68850708007812 39.123779296875 24.387939453125 C 36.70367050170898 22.82554054260254 33.89437866210938 21.99969863891602 30.99959945678711 21.99969863891602 C 28.10513877868652 21.99969863891602 25.29606819152832 22.8255500793457 22.87608909606934 24.38796043395996 C 20.86161804199219 25.68856239318848 19.19575881958008 27.44137191772461 18.00525283813477 29.50199890136719 C 22.03839111328125 30.48407936096191 26.50226211547852 31.00040054321289 30.99959945678711 31.00040054321289 M 30.99959945678711 32.00040054321289 C 25.77477836608887 32.00040054321289 20.85280990600586 31.33343887329102 16.53414916992188 30.15513038635254 C 19.09944915771484 24.74293899536133 24.61244964599609 20.99969863891602 30.99959945678711 20.99969863891602 C 37.38740921020508 20.99969863891602 42.90052795410156 24.74287033081055 45.46588897705078 30.15505981445312 C 41.14709091186523 31.33337020874023 36.22470092773438 32.00040054321289 30.99959945678711 32.00040054321289 Z" stroke="none" fill="#1988d9"/>
            </g>
            <g id="Intersection_2" data-name="Intersection 2" transform="translate(47 53.001) rotate(180)" fill="none">
                <path d="M16.534,30.155a16.006,16.006,0,0,1,28.932,0A55.392,55.392,0,0,1,31,32,55.387,55.387,0,0,1,16.534,30.155Z" stroke="none"/>
                <path d="M 30.99959945678711 31.00040054321289 C 35.49715423583984 31.00040054321289 39.96134185791016 30.48404884338379 43.99476623535156 29.50192832946777 C 42.80422592163086 27.44129753112793 41.13832092285156 25.68850708007812 39.123779296875 24.387939453125 C 36.70367050170898 22.82554054260254 33.89437866210938 21.99969863891602 30.99959945678711 21.99969863891602 C 28.10513877868652 21.99969863891602 25.29606819152832 22.8255500793457 22.87608909606934 24.38796043395996 C 20.86161804199219 25.68856239318848 19.19575881958008 27.44137191772461 18.00525283813477 29.50199890136719 C 22.03839111328125 30.48407936096191 26.50226211547852 31.00040054321289 30.99959945678711 31.00040054321289 M 30.99959945678711 32.00040054321289 C 25.77477836608887 32.00040054321289 20.85280990600586 31.33343887329102 16.53414916992188 30.15513038635254 C 19.09944915771484 24.74293899536133 24.61244964599609 20.99969863891602 30.99959945678711 20.99969863891602 C 37.38740921020508 20.99969863891602 42.90052795410156 24.74287033081055 45.46588897705078 30.15505981445312 C 41.14709091186523 31.33337020874023 36.22470092773438 32.00040054321289 30.99959945678711 32.00040054321289 Z" stroke="none" fill="#1988d9"/>
            </g>
            <g id="Intersection_3" data-name="Intersection 3" transform="translate(0 -10)" fill="none">
                <path d="M14.56,41.936a25.619,25.619,0,0,1,0-31.872Q15.271,10,16,10a16,16,0,0,1,0,32Q15.272,42,14.56,41.936Z" stroke="none"/>
                <path d="M 16.00020027160645 41.00030136108398 C 20.00666999816895 41.00030136108398 23.77349090576172 39.43994140625 26.60676956176758 36.60667037963867 C 29.44004058837891 33.77339172363281 31.00040054321289 30.00657081604004 31.00040054321289 26.00010108947754 C 31.00040054321289 21.99330139160156 29.44005012512207 18.22637176513672 26.60678100585938 15.39321136474609 C 23.77359962463379 12.56014156341553 20.00678062438965 10.99990081787109 16.00020027160645 10.99990081787109 C 15.6930456161499 10.99990081787109 15.38392639160156 11.00928783416748 15.07680797576904 11.02788257598877 C 13.50606441497803 13.08329391479492 12.27506828308105 15.34804439544678 11.41519069671631 17.76570129394531 C 10.47614002227783 20.40595054626465 10 23.17640113830566 10 26.00010108947754 C 10 28.82380104064941 10.47614002227783 31.59424018859863 11.41518020629883 34.23448181152344 C 12.27505779266357 36.65212631225586 13.5060567855835 38.91688919067383 15.07680892944336 40.9723014831543 C 15.38399124145508 40.99090576171875 15.69312000274658 41.00030136108398 16.00020027160645 41.00030136108398 M 16.00020027160645 42.00030136108398 C 15.51476001739502 42.00030136108398 15.03425025939941 41.97864151000977 14.55983066558838 41.93632125854492 C 11.07472991943359 37.53466033935547 9 32.00543212890625 9 26.00010108947754 C 9 19.99477195739746 11.07474040985107 14.46551132202148 14.55983066558838 10.06386089324951 C 15.03425025939941 10.02154064178467 15.51476001739502 9.999900817871094 16.00020027160645 9.999900817871094 C 24.83640098571777 9.999900817871094 32.00040054321289 17.16300201416016 32.00040054321289 26.00010108947754 C 32.00040054321289 34.83629989624023 24.83640098571777 42.00030136108398 16.00020027160645 42.00030136108398 Z" stroke="none" fill="#1988d9"/>
            </g>
            <g id="Intersection_4" data-name="Intersection 4" transform="translate(32 42.001) rotate(180)" fill="none">
                <path d="M14.56,41.936a25.619,25.619,0,0,1,0-31.872Q15.271,10,16,10a16,16,0,0,1,0,32Q15.272,42,14.56,41.936Z" stroke="none"/>
                <path d="M 16.00020027160645 41.00030136108398 C 20.00666999816895 41.00030136108398 23.77349090576172 39.43994140625 26.60676956176758 36.60667037963867 C 29.44004058837891 33.77339172363281 31.00040054321289 30.00657081604004 31.00040054321289 26.00010108947754 C 31.00040054321289 21.99330139160156 29.44005012512207 18.22637176513672 26.60678100585938 15.39321136474609 C 23.77359962463379 12.56014156341553 20.00678062438965 10.99990081787109 16.00020027160645 10.99990081787109 C 15.6930456161499 10.99990081787109 15.38392639160156 11.00928783416748 15.07680797576904 11.02788257598877 C 13.50606441497803 13.08329391479492 12.27506828308105 15.34804439544678 11.41519069671631 17.76570129394531 C 10.47614002227783 20.40595054626465 10 23.17640113830566 10 26.00010108947754 C 10 28.82380104064941 10.47614002227783 31.59424018859863 11.41518020629883 34.23448181152344 C 12.27505779266357 36.65212631225586 13.5060567855835 38.91688919067383 15.07680892944336 40.9723014831543 C 15.38399124145508 40.99090576171875 15.69312000274658 41.00030136108398 16.00020027160645 41.00030136108398 M 16.00020027160645 42.00030136108398 C 15.51476001739502 42.00030136108398 15.03425025939941 41.97864151000977 14.55983066558838 41.93632125854492 C 11.07472991943359 37.53466033935547 9 32.00543212890625 9 26.00010108947754 C 9 19.99477195739746 11.07474040985107 14.46551132202148 14.55983066558838 10.06386089324951 C 15.03425025939941 10.02154064178467 15.51476001739502 9.999900817871094 16.00020027160645 9.999900817871094 C 24.83640098571777 9.999900817871094 32.00040054321289 17.16300201416016 32.00040054321289 26.00010108947754 C 32.00040054321289 34.83629989624023 24.83640098571777 42.00030136108398 16.00020027160645 42.00030136108398 Z" stroke="none" fill="#1988d9"/>
            </g>
            <path id="Subtraction_5" data-name="Subtraction 5" d="M15.556,24.817A15.828,15.828,0,0,1,9.8,22.745a15.871,15.871,0,0,0,5.755,2.069v0Zm6.747-.4v0a15.952,15.952,0,0,0,3.78-1.6,15.77,15.77,0,0,1-3.778,1.6ZM5.289,18.723c-.181-.236-.358-.479-.525-.723.179.256.355.5.526.722ZM6,15.244H6V2.757A57.717,57.717,0,0,0,18,4,57.522,57.522,0,0,0,29,2.963V15.038A57.545,57.545,0,0,0,18,14,57.719,57.719,0,0,0,6,15.244Z" transform="translate(-2 7)" fill="#fff"/>
            <path id="Path_4219" data-name="Path 4219" d="M6.04-2.456H3.646a7.521,7.521,0,0,1-.784-.036,3.56,3.56,0,0,1-.609-.113,2.059,2.059,0,0,1-.485-.206,2.115,2.115,0,0,1-.413-.325A2.774,2.774,0,0,1,.68-4.262a5.1,5.1,0,0,1-.232-1.62A4.575,4.575,0,0,1,.84-7.868,2.328,2.328,0,0,1,1.965-9.028a2.974,2.974,0,0,1,.769-.227,5.622,5.622,0,0,1,.975-.083H6.04V-8.11H3.884a1.764,1.764,0,0,0-1.465.557,1.388,1.388,0,0,0-.263.6,4.374,4.374,0,0,0-.088.934,4.379,4.379,0,0,0,.16,1.279,1.4,1.4,0,0,0,.469.743,2.025,2.025,0,0,0,1.238.31h2.1Zm6.943-4.086v4.086H9.909q-.4,0-.712-.026A3.508,3.508,0,0,1,8.64-2.57a2.211,2.211,0,0,1-.454-.165,2.029,2.029,0,0,1-.392-.258,2.578,2.578,0,0,1-.8-1.145A4.873,4.873,0,0,1,6.71-5.871a4.923,4.923,0,0,1,.273-1.723,2.615,2.615,0,0,1,.8-1.156,3.47,3.47,0,0,1,2.187-.588h3.013V-8.11H10.146a1.766,1.766,0,0,0-1.4.49A2.462,2.462,0,0,0,8.32-6.006,4.691,4.691,0,0,0,8.449-4.85a1.675,1.675,0,0,0,.366.753,1.887,1.887,0,0,0,1.382.413h1.321v-1.63H9.6V-6.542Zm5.076,2.579,1.805-5.375H22v6.882H20.608V-7.625L18.8-2.456H17.3l-1.7-5.169v5.169H14.2V-9.338h2.146Z" transform="translate(4 21.457)" fill="#00406f"/>
            <g id="Ellipse_83" data-name="Ellipse 83" transform="translate(0 0)" fill="none" stroke="#1988d9" strokeWidth="2">
                <circle cx="16" cy="16" r="16" stroke="none"/>
                <circle cx="16" cy="16" r="15" fill="none"/>
            </g>
        </svg>
    )
}