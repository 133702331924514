
import React from 'react'
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom'
import {IntlProvider} from "react-intl"
import LandingPage from './views/landing'
import IndexPage from './views/index'
import getStrings from './actions/StringsActions'
import isEmpty from 'lodash/isEmpty'
import isKeyPressed from "./utils/isKeyPressed"

import './styles/main.scss'
import {library} from '@fortawesome/fontawesome-svg-core'

import {
    faPlus,
    faMinus,
    faExclamationCircle,
    faQuestionCircle
} from '@fortawesome/free-solid-svg-icons'

import {
    faFacebookSquare,
    faTwitterSquare,
    faYoutube
} from '@fortawesome/free-brands-svg-icons'

library.add({
    faPlus,
    faMinus,
    faExclamationCircle,
    faQuestionCircle,
    faFacebookSquare,
    faTwitterSquare,
    faYoutube
})

const locale = window.location.host.includes('.sk') || window.location.host.includes('.cloud') ? 'sk' : 'cs'

export default class App extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            messages: {messages: {}, identifiers: {}},
            strType: 'messages'
        }
        document.addEventListener("keydown", (e) => this.keyListenner(e), false)
    }

    componentDidMount() {
        getStrings().then(res => {
            if (res === false) {
                this.setState({messages: false})
            } else {
                const identifiers = {}
                Object.keys(res).map(k => {
                    identifiers[k] = k
                })
                this.setState({messages: {messages: res, identifiers: identifiers}})
            }
        })
    }

    keyListenner(e) {
        if (isKeyPressed(e, 68)) { // 68 = d = development
            this.setState({strType: this.state.strType === 'messages' ? 'identifiers' : 'messages'})
        }
    }

    render() {
        const {messages, strType} = this.state
        if (messages === false) {
            return "unexpected_error"
        } else if (isEmpty(messages[strType])) {
            return null
        }

        return (
            <IntlProvider locale={locale} messages={messages[strType]}>
                <Router>
                    <Switch>
                        <Route
                            path="/:slug"
                            exact
                            render={props => <LandingPage {...props} />}
                        />
                        <Route
                            path="/"
                            exact
                            render={props => <IndexPage {...props} />}
                        />
                        <Redirect to="/"/>
                    </Switch>
                </Router>
            </IntlProvider>
        )
    }
}