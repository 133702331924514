import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import App from "./App"

const { detect } = require('detect-browser')
const browser = detect()

axios.defaults.headers.common['Optional'] = "4ujFwHp2CvxZjWtwKqJM4Vt35HDE3SXHfnfSad5P73CGx9qf"
axios.defaults.headers.common['Browser'] = btoa(JSON.stringify(browser))
axios.defaults.headers.post['Content-Type'] = 'application/json'

const isIE = browser.name === 'ie'

if(isIE){
    document.body.id = "is-ie"
}

ReactDOM.render(
            <App/>,
    document.getElementById('root')
)