import axios from 'axios'
import {fetchUrl} from "../constants/constants"

export const checkUser = (email) => {
    return axios({
        method: 'GET',
        url: fetchUrl + 'user/check/' + encodeURI(email),
    }).then(res => {
        return res.data.data.found
    }).catch(err => {
        return false
    })
}

export const loadUser = (data) => {
    return axios({
        method: 'POST',
        url: fetchUrl + 'user/load',
        data
    }).then(res => {
        return res.data.data
    }).catch(err => {
        if(err.response && err.response.status){
            if(err.response.status === 401){
                return "unauthorized"
            }
        }
        return false
    })
}