import React from "react";
import Logo from '../img/cgm-logo.png'

export default () => {
    return(
        <section className="subheader">
            <p>Synchronizing Healthcare</p>
            <img src={Logo} alt="cgm-logo"/>
        </section>
    )
}