import React from "react"
import {Row, Col} from 'react-bootstrap'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import IntlMessage from "./IntlMessage"
import CgmIcon from "./svg/cgmIcon"
import {useIntl} from 'react-intl'

const cgmUrl = window.location.origin.replace('objednavky.', 'www.')

export default () => {
    const intl = useIntl()
    return (
        <footer>
            <Row>
                <Col lg={4}>
                    <h5><IntlMessage id="heading_contact"/></h5>
                    <p><IntlMessage id="contact_address_1"/></p>
                    <p><IntlMessage id="contact_address_2"/></p>
                    <p><IntlMessage id="contact_address_3"/></p>
                    <p><a href={intl.messages.contact_url}>{intl.messages.contact_url}</a></p>
                    <p><IntlMessage id="contact_identification_number"/></p>
                </Col>
                <Col lg={4}>
                    <h5><IntlMessage id="heading_store"/></h5>
                    <p><IntlMessage id="contact_phone"/></p>
                    <p><IntlMessage id="contact_email"/>{" "}<a href={`mailto:${intl.messages.contact_mail}`}>{intl.messages.contact_mail}</a></p>
                </Col>
                <Col lg={4}>
                    <h5><IntlMessage id="heading_links"/></h5>
                    <p><a
                        href={cgmUrl + "/informace/prohlaseni-o-ochrane-udaju-portal-cgm-svet/"}
                        target="_blank"><IntlMessage id="label_privacy_statement"/></a></p>
                    <div className="social-icons">
                        <a href={cgmUrl} className="cgm">
                            <CgmIcon/>
                        </a>
                        {
                            intl.messages.contact_fb && (
                                <a href={intl.messages.contact_fb} className="fb">
                                    <FontAwesomeIcon icon={["fab", "facebook-square"]}/>
                                </a>
                            )
                        }
                        {
                            intl.messages.contact_tw && (
                                <a href={intl.messages.contact_tw} className="tw">
                                    <FontAwesomeIcon icon={["fab", "twitter-square"]}/>
                                </a>
                            )
                        }
                        {
                            intl.messages.contact_yb && (
                                <a href={intl.messages.contact_yb} className="yt">
                                    <FontAwesomeIcon icon={["fab", "youtube"]}/>
                                </a>
                            )
                        }
                    </div>
                </Col>
            </Row>
        </footer>
    )
}