import axios from 'axios'
import {fetchUrl} from "../constants/constants"

export default () => {
    return axios({
        method: 'GET',
        url: fetchUrl + 'strings',
    }).then(res => {
        return res.data.data
    }).catch(err => {
        return false
    })
}