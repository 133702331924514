import React from "react"
import {FormattedMessage, useIntl} from 'react-intl'
import {Tooltip, OverlayTrigger} from 'react-bootstrap'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

export default (props) => {
    const {id} = props
    const intl = useIntl()

    let hint = intl.messages[`${id}_hint`]
    let tooltip = false

    if(hint){
        tooltip = <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={props.id}> {hint} </Tooltip>}>
                    <span data-tip data-for={id} className="hint">
                        <FontAwesomeIcon icon="question-circle"/>
                    </span>
        </OverlayTrigger>
    }

    return (
        <>
            <FormattedMessage id={id}/> {tooltip}
        </>
    )
}