import axios from 'axios'
import {fetchUrl} from "../constants/constants"

export default (data) => {
    return axios({
        method: 'POST',
        url: fetchUrl + 'order',
        data
    }).then(res => {
        return true
    }).catch(err => {
        return false
    })
}