import isEmpty from 'lodash/isEmpty'
export default (form, name = '') => {
    const errors = {}

    Object.keys(form).map(key => {
        if (key === name || name === '') {
            switch (key) {
                case 'email':
                    if (form.email === '') {
                        errors.email = 'message_fill_email'
                    } else {
                        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
                            errors.email = 'message_invalid_email'
                        }
                    }
                    break
                case 'identification_number':
                    if (form.identification_number === '') {
                        errors.identification_number = 'message_fill_identification_number'
                    } else {
                        if (!/^\d{8}$/.test(form.identification_number)) {
                            errors.identification_number = 'message_invalid_identification_number'
                        }
                    }
                    break
                case 'company_name':
                    if (form.company_name === '') {
                        errors.company_name = 'message_fill_company_name'
                    }
                    break
                case 'phone':
                    if (form.phone === '') {
                        errors.phone = 'message_fill_phone'
                    } else {
                        if (!/^(\+\d{3})*(\s)*(\d{3})+(\s)*(\d{3})+(\s)*(\d{3})+$/.test(form.phone)) {
                            errors.phone = 'message_invalid_phone'
                        }
                    }
                    break
                case 'product':
                    if (form.product === '') {
                        errors.product = 'message_fill_product'
                    }
                    break
                case 'materials':
                    if (isEmpty(form.materials)) {
                        errors.materials = 'message_fill_material'
                    }
                    break
                default:
                    break
            }
        }
    })

    return errors
}