import React, {useEffect, useState} from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Subheader from "../components/Subheader"
import Main from "../components/Main"
import getInfo from "../actions/InfoActions"
import {Container} from "react-bootstrap"

export default (props) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    useEffect(() => {
        getInfo(props.match.params.slug).then(resp => {
            if(resp === false){
                props.history.replace('/')
            }else{
                setData(resp)
                setTimeout(() => {
                    setLoading(false)
                }, 100)
            }
        })
    }, [])

    if(!loading && data.bg_color){
        document.body.style.background = data.bg_color
    }

    return(
        <Container >
            <Header heading={loading ? '' : (data.name || '')} background={loading ? '' : data.image} loading={loading}/>
            <Subheader/>
            <Main data={data} loading={loading}/>
            <Footer/>
        </Container>
    )
}